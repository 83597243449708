import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import DayForm from './views/DayForm';
import { CheckoutProvider } from './contexts/CheckoutContext';
import ProductForm from './views/ProductForm';
import Delivery from './views/Delivery';
import CheckoutInfo from './views/CheckoutInfo';
import PaymentSuccess from './views/PaymentSuccess';
import ClientDataForm from './views/ClientDataForm';
import PurchaseSuccess from './views/PurchaseSuccess';
import PaymentFailure from './views/PaymentFailure';
import PaymentInProcess from './views/PaymentInProcess';
import PurchaseInProcess from './views/PurchaseInProcess';
import { initMercadoPago } from '@mercadopago/sdk-react';
import SellerSelect from './views/SellerSelect';

function App() {

  useEffect(() => {
    // Initialize MercadoPago once for the entire app
    initMercadoPago(process.env.REACT_APP_PUBLIC_KEY, {locale: 'es-CL'});
  }, []);

  return (
    <CheckoutProvider>
      <BrowserRouter>
          <div className=''>
            <Routes>
              <Route path="/delivery" element={<Delivery />} />
              <Route path="/client" element={<ClientDataForm />} />
              <Route path="/seller" element={<SellerSelect />} />
              <Route path="/" element={<ProductForm />} />
              <Route path="/date" element={<DayForm />} />
              <Route path="/checkout/info" element={<CheckoutInfo />} />
              <Route path="/payment/success" element={<PaymentSuccess />} />
              <Route path="/purchase/success/:payment_id" element={<PurchaseSuccess />} />
              <Route path="/payment/failure" element={<PaymentFailure />} />
              <Route path="/payment/inprocess" element={<PaymentInProcess />} />
              <Route path="/purchase/inprocess/:payment_id" element={<PurchaseInProcess />} />
            </Routes>
          </div>
      </BrowserRouter>
    </CheckoutProvider>
  );
}

export default App;