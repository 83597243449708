import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CheckoutContext from "../contexts/CheckoutContext";
import StatusScreenWrapper from "../components/StatusScreenWrapper";
import WaMeLink from "../components/WaMeLink";

const PaymentFailure = () => {

    const location = useLocation();

    const navigate = useNavigate();

    const { clientData } = useContext(CheckoutContext);
    const [renderResult, setRenderResult] = useState(false);

    // Esta función te ayuda a extraer los parámetros de la URL
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    const queryParams = getQueryParams(location.search);

    // Ejemplo de cómo obtener algunos parámetros
    // const collectionId = queryParams.get("collection_id");
    const paymentId = queryParams.get("payment_id");
    console.log("Payment ID", paymentId);
    // const status = queryParams.get("status");
    // const externalReference = queryParams.get("external_reference");

    return (
        <div>
            <div>
                <StatusScreenWrapper payment_id={paymentId} setRenderResult={setRenderResult}/>
            </div>
            <div className="bg-red-550 text-center mx-2 mt-2 rounded-xl">
                <p>{clientData.name}, su pedido no será despachado, el pago fue rechazado, </p>
                <p>Por favor Intentelo nuevamente</p>
            </div>
            <div className="text-center mx-2 h-8 mt-2">
                <button className="bg-slate-600 text-white rounded w-full h-full" onClick={() => navigate("/checkout/info")}>Volver a Intentar</button>
            </div>
            <WaMeLink />
        </div>
    );
}

export default PaymentFailure;

