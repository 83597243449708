
import React, { useEffect } from 'react';
import { Wallet } from '@mercadopago/sdk-react';

function WalletButton ({ setIsLoadingMePaButton, preference }) {

  return (
    <div>
      <p className='font-bold pl-2'>Paga con trajeta de Crédito o Debito:</p>
      <Wallet
          initialization={{preferenceId: `${preference.id}`}}
          onReady={() => setIsLoadingMePaButton(false)}
      />
    </div>
  );
};

export default WalletButton;
