import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CheckoutContext from "../contexts/CheckoutContext";
import WaMeLink from "../components/WaMeLink";



const PaymentInProcess = () => {

    const navigate = useNavigate();

    const { clientData } = useContext(CheckoutContext);

    return (
        <div>
            <div className="bg-gray-100 text-center mx-2 mt-2 rounded-xl p-6 shadow">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Atención {clientData.name}</h2>
                <p className="text-lg text-gray-800 mb-3">Tu pedido no ha podido ser procesado, el pago ha sido rechazado.</p>
                <p className="text-lg text-gray-800 mb-3">Contactanos si necesitas asistencia. Agradecemos tu comprensión y preferencia.</p>
            </div>
            <div className="text-center mx-2 mt-4">
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold rounded-lg w-full h-12 text-lg transition duration-150 ease-in-out" onClick={() => navigate("/checkout/info")}>Volver a Intentar</button>
            </div>
            <WaMeLink />
        </div>

    );
}

export default PaymentInProcess;