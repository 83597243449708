import React, { useState, useEffect, useContext, useRef } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import CheckoutContext from "../contexts/CheckoutContext";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";

function SellerForm () {
    const navigate = useNavigate();

    const formRef = useRef(null);
    const { setSeller } = useContext(CheckoutContext);

    const [localSeller, setLocalSeller] = useState("");
    const [validLocalSeller, setValidLocalSeller] = useState(false);
    const [sellerUsers, setSellerUsers] = useState();
    const [loadingSellers, setLoadingSellers] = useState(true);

    const [errMsg, setErrMsg] = useState("");

    async function getSellers () {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}user/seller`);
        setSellerUsers(response.data);
        setLoadingSellers(false);
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    async function handleClickContinue (e) {
        e.preventDefault();
        // if button enabled with JS hack
        // const v1 = SELLER_REGEX.test(localSeller);

        if (localSeller === "Otro") {
            setValidLocalSeller(true); // "Otro" is valid
        } 
        else {
            const isValid = sellerUsers.some((seller) => seller.id == localSeller);
            if (!isValid) {
                setErrMsg("Invalid Entry");
                return;
            }
        }

        const data = {
            id: localSeller
        };

        setSeller(data);
        navigate("/checkout/info");
    };

    useEffect(() => {
        getSellers()
    }, []);

    useEffect(() => {
        if (localSeller === "Otro") {
            setValidLocalSeller(true); // "Otro" is valid
        } else {
            if (sellerUsers) {
                const isValid = sellerUsers.some((seller) => seller.id == localSeller);
                setValidLocalSeller(isValid);
            }
        }
        setErrMsg(''); // Clear error message
    }, [localSeller, sellerUsers]);

    if (loadingSellers) {
        return(
            <div className="text-center">
                <LoadingSpinner/>
            </div>
        )
    }
    else {
        return(
            <div ref={formRef} className="content-center px-8 py-6 text-left bg-white shadow-xl flex flex-col gap-4 mt-4 mx-2">
                <p className={errMsg ? "bg-red-600 pl-4 rounded-lg" : "hidden"} aria-live="assertive">{errMsg}</p>
                <p className="text-center text-3xl font-bold text-gray-600">¿Quién te envió el link de compra?</p>
                <form onSubmit={handleClickContinue} className="flex flex-col justify-evenly grow pb-4">
                    
                    <label htmlFor="seller">
                        Vendedor:
                        <span className={validLocalSeller ? "ml-2 text-green-600" : "hidden"}>
                            <FontAwesomeIcon icon={faCheck} />
                        </span>
                        <span className={validLocalSeller || !localSeller ? "hidden" : "ml-2 text-red-600"}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                    </label>
                    <select
                        className="bg-gray-300 rounded-lg pl-2 mb-2"
                        type="text"
                        id="commune"
                        autoComplete="off"
                        onChange={(e) => setLocalSeller(e.target.value)}
                        required
                        aria-invalid={validLocalSeller ? "false" : "true"}
                        aria-describedby="communeNote"
                        >
                        <option value="selecciona">seleccionar</option>
                        {sellerUsers.map((seller, index) => (
                            <option key={index} value={seller.id}>{seller.firstName} {seller.lastName}</option>
                        ))}
                        <option value="Otro">Otro</option>
                    </select>
                    <p id="communeNote" className={ localSeller &&
                        !validLocalSeller ? "text-xs rounded-lg bg-red-300 p-1 relative" : "hidden"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Debes seleccionar "Otro" si no existe tu opción
                    </p>

                    <div className="text-center text-white font-bold mt-5">
                        <input type="submit" disabled={!(validLocalSeller)} className="bg-gray-700 hover:bg-gray-900 w-full h-10 disabled:bg-gray-300" value="CONTINUAR" />
                    </div>
                </form>
            </div>
        )
    }
}

export default SellerForm;