import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import StatusScreenWrapper from "../components/StatusScreenWrapper";



const PaymentSuccess = () => {

    const location = useLocation();

    const navigate = useNavigate();

    const [renderResult, setRenderResult] = useState(false);

    // Esta función te ayuda a extraer los parámetros de la URL
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    const queryParams = getQueryParams(location.search);

    // Ejemplo de cómo obtener algunos parámetros
    // const collectionId = queryParams.get("collection_id");
    const paymentId = queryParams.get("payment_id");
    console.log("Payment ID", paymentId);
    // const status = queryParams.get("status");
    // const externalReference = queryParams.get("external_reference");

    return (
        <div>
            <StatusScreenWrapper payment_id={paymentId} setRenderResult={setRenderResult}/>
            {renderResult ? (<div className="text-center mx-2 h-10 mt-2">
                <button className="bg-green-600 text-white rounded-b-xl w-full h-full" onClick={() => navigate(`/purchase/success/${paymentId}`)}>Continuar</button>
            </div>) : null }
        </div>
    );
}

export default PaymentSuccess;