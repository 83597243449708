import React, { useState, useContext, useRef, useEffect } from "react";
import CheckoutContext from "../contexts/CheckoutContext";
import { useNavigate } from "react-router-dom";
import DeliveryForm from "./DeliveryForm";


function DeliveryMemoryForm (props) {

    const navigate = useNavigate();
    const directionFormRef = useRef(null);

    const { setDeliveryDirection } = useContext(CheckoutContext);

    const [useOtherAddress, setUseOtherAddress] = useState(false);
    const [isAddressSelected, setIsAddressSelected] = useState(false);

    function handleAddressSelection(value) {
        if (value === "other") {
            setUseOtherAddress(true);
        } else {
            setUseOtherAddress(false);
            for (let i = 0; i < props.previousPurchases.length; i++) {
                if (props.previousPurchases[i].address.id === value) {
                    const data = {
                        streetName: props.previousPurchases[i].address.streetName,
                        streetNumber: props.previousPurchases[i].address.streetNumber,
                        commune: props.previousPurchases[i].address.commune,
                        city: props.previousPurchases[i].address.city,
                        departmentNumber: props.previousPurchases[i].address.departmentNumber,
                        houseNumber: props.previousPurchases[i].address.houseNumber,
                    };
                    setDeliveryDirection(data);
                }
            }
            setIsAddressSelected(true);
        }
    }



    function handleClickContinue (e) {
        e.preventDefault();
        navigate("/seller");
    };

    useEffect(() => {
        if (useOtherAddress) {
            directionFormRef.current.scrollIntoView({ behavior: "smooth" });
        }
    } ,[useOtherAddress]);

    return (
        <div className="mt-4">
          <div className="shadow-xl mx-2 overflow-hidden">
            <div className="font-bold text-2xl px-4 text-center text-gray-600 mt-2">
              <p>Seleccione Direccion de Despacho</p>
            </div>
            <div className="text-left mx-2 px-2 text-gray-600">
              <div className="mt-2 rounded-xl py-1">
                <div className="mx-2">
                  <p>Direcciones de despacho usadas anteriormente:</p>
                </div>
                <div className="ml-4 mr-2">
                {props.previousPurchases.map((purchase) => (
                  <div key={purchase.address.id} className="mt-2 flex">
                    <input
                      type="radio"
                      id={purchase.address.id}
                      name="selected_address"
                      value={purchase.address.id}
                      onChange={() => handleAddressSelection(purchase.address.id)}
                      className="mr-2"
                    />
                    <label htmlFor={purchase.address.id} className="pl-1">
                      {purchase.address.streetName} {purchase.address.streetNumber}, {purchase.address.commune}
                      { (purchase.address.houseNumber.length > 0) ? ` (Casa: ${purchase.address.houseNumber})` : null}
                      { (purchase.address.departmentNumber.length > 0) ? ` (Dpto: ${purchase.address.departmentNumber})` : null}
                    </label>
                  </div>
                ))}
                </div>
              </div>

              <div key="other" className="my-2">
                <input
                  type="radio"
                  id="other"
                  name="selected_address"
                  value="other"
                  onChange={() => handleAddressSelection("other")}
                  className="mr-2"
                />
                <label htmlFor="other">Usar otra dirección</label>
              </div>
            </div>
          </div>
          <div className="mt-4">
            {useOtherAddress ? null : (
              <div className="mx-2">
                <button
                  onClick={handleClickContinue}
                  className="h-10 bg-gray-700 hover:bg-gray-900 disabled:bg-gray-300 w-full text-white font-bold"
                  disabled={!isAddressSelected}
                >
                  Continuar
                </button>
              </div>
            )}
            {useOtherAddress && <div ref={directionFormRef}><DeliveryForm/></div>}
          </div>
        </div>
      );
}

export default DeliveryMemoryForm;