import React, { useContext, useEffect, useRef } from "react";
import CheckoutContext from "../contexts/CheckoutContext";
import { useNavigate } from "react-router-dom";
import ProductsResume from "../components/ProductsResume";
import DateResume from "../components/DateResume";
import ClientDataResume from "../components/ClientDataResume";
import DeliveryDirectionResume from "../components/DeliveryDirectionResume";
import SellerForm from "../components/SellerForm";

function SellerSelect () {

    const selectSellerRef = useRef(null);

    const navigate = useNavigate();

    const { items, deliveryDate, deliveryDirection, clientData } = useContext(CheckoutContext);

    useEffect(() => {
        if (items.length === 0 || !clientData.name || !clientData.lastName || !clientData.phoneNumber || !clientData.email || deliveryDate.length !== 4 || Object.keys(deliveryDirection).length === 0 ) {
            navigate("/");
        }
        selectSellerRef.current.scrollIntoView({ behavior: "smooth" });
    } ,[]);

    return(
        <div>
            <div className="mt-4 flex flex-col gap-2">
                <div>
                    <ProductsResume items={items}/>
                </div>
                <div>
                    <DateResume deliveryDate={deliveryDate}/>
                </div>
                <div>
                    <DeliveryDirectionResume deliveryDirection={deliveryDirection}/>
                </div>
                <div>
                    <ClientDataResume clientData={clientData}/>
                </div>
            </div>
            <div className="mb-10 flex justify-center">
                <div ref={selectSellerRef}><SellerForm/></div>
            </div>
        </div>
    )
}

export default SellerSelect;